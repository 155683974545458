<script>
import * as config from '../../config';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    marginTop: {
      type: String,
      default: '0px',
    },
    marginBottom: {
      type: String,
      default: '0px',
    },
  },
  computed: {
    background() {
      return this.block.fields.Background;
    },
    imageSource() {
      const imageWidth = Math.min(3840, this.background?.width || 800);
      return this.$toLitiumMediaUrl(this.background.id, { maxWidth: imageWidth });
    },
    videoSource() {
      return config.litiumBaseUrl + this.background.url;
    },
    element() {
      return this.backgroundLink ? 'router-link' : 'div';
    },
    backgroundLink() {
      const { fields } = this.block;
      return fields.CompositeLink_Link || undefined;
    },
    isVideo() {
      return this.background?.templateId === 'Movie';
    },
    height() {
      return this.block.fields.Height?.replace('%', 'vh') || '50vh';
    },
    overlays() {
      return this.block.fields.Overlays;
    },
    overlayPositions() {
      return this.overlays?.reduce((res, overlay) => {
        const positions = [];
        const posPhone = overlay.OverlayPositionPhone?.value || 'center';
        const posDesktop = overlay.OverlayPositionDesktop?.value || 'center';

        function addPosition(value) {
          if (value === 'hidden') {
            return;
          }
          if (value && positions.indexOf(value) < 0) {
            positions.push(value);
          }
        }

        addPosition(posPhone);
        addPosition(posDesktop);

        positions.forEach((pos) => {
          if (!res[pos]) {
            res[pos] = [];
          }
          const showOn = [];
          if (pos === posPhone) {
            showOn.push('phone');
          }
          if (pos === posDesktop) {
            showOn.push('desktop');
          }
          res[pos].push({
            showOn,
            overlay,
            key: Math.random().toString(36).slice(2),
          });
        });
        return res;
      }, {});
    },
  },
  methods: {
    getOverlayClassFor(item) {
      const classes = [
        'hero-block__overlay-item',
        `hero-block__overlay-item--phone-text-${
          item.overlay.OverlayTextAlignmentPhone?.value || 'center'
        }`,
        `hero-block__overlay-item--desktop-text-${
          item.overlay.OverlayTextAlignmentDesktop?.value || 'center'
        }`,
        `hero-block__overlay-item--${this.block.systemId}`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`hero-block__overlay-item--${device}`);
      });
      return classes;
    },
  },
};
</script>

<template>
  <component
    :is="element"
    :to="backgroundLink"
    v-if="background"
    ref="heroContainer"
    class="hero-block"
    :style="{
      marginTop: marginTop,
      marginBottom: marginBottom,
      height: height,
    }"
  >
    <img
      class="hero-block__image"
      v-if="!isVideo"
      :src="imageSource"
    />
    <video
      class="hero-block__video"
      v-if="isVideo"
      playsinline
      autoplay
      loop
      muted
    >
      <source :src="videoSource" />
    </video>
    <div
      v-for="(items, field) in overlayPositions"
      :key="field"
      :class="[`hero-block__overlay--${field}`, 'hero-block__overlay']"
    >
      <div class="hero-block__overlay-container">
        <div
          v-for="item in items"
          :key="item.key"
          v-html="item.overlay.OverlayBody"
          :class="getOverlayClassFor(item)"
        ></div>
      </div>
    </div>
  </component>
</template>

<style>
.hero-block {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  max-height: 100vh;
}

.hero-block__video,
.hero-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.hero-block__overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--layout-maxWidth);
  display: flex;
  flex-direction: column;
  padding: 70px;
  pointer-events: none;
}

.hero-block__overlay-item {
  padding: 10px;
  display: none;
  max-height: 100%;
  white-space: normal;
  pointer-events: auto;
}

.hero-block__overlay-item h1 {
  letter-spacing: -0.3px;
  font-size: 55px;
  margin: 0;
  line-height: 60px;
}

.hero-block__overlay-item h2 {
  font-size: 40px;
  margin: 0;
  line-height: 45px;
}

.hero-block__overlay-item h3 {
  font-size: 30px;
  margin: 0;
  line-height: 30px;
}

.hero-block__overlay-item p {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: 8px 0;
}

@media (max-width: 767px) {
  .hero-block__overlay {
    padding: 35px 10px;
  }

  .hero-block__overlay-item {
    padding: 5px;
    width: 100%;
  }

  .hero-block__overlay-item--phone {
    display: block;
  }

  .hero-block__overlay-item--phone-text-left {
    text-align: left;
  }

  .hero-block__overlay-item--phone-text-center {
    text-align: center;
  }

  .hero-block__overlay-item--phone-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .hero-block__overlay-item--desktop {
    display: block;
  }

  .hero-block__overlay-item--desktop-text-left {
    text-align: left;
  }

  .hero-block__overlay-item--desktop-text-center {
    text-align: center;
  }

  .hero-block__overlay-item--desktop-text-right {
    text-align: right;
  }
}

/* Overlay positions */
.hero-block__overlay--top {
  align-items: center;
  justify-content: flex-start;
}

.hero-block__overlay--topleft {
  align-items: flex-start;
  justify-content: flex-start;
}

.hero-block__overlay--topright {
  align-items: flex-end;
  justify-content: flex-start;
}

.hero-block__overlay--left {
  align-items: flex-start;
  justify-content: center;
}

.hero-block__overlay--right {
  align-items: flex-end;
  justify-content: center;
}

.hero-block__overlay--center {
  align-items: center;
  justify-content: center;
}

.hero-block__overlay--bottom {
  align-items: center;
  justify-content: flex-end;
}

.hero-block__overlay--bottomleft {
  align-items: flex-start;
  justify-content: flex-end;
}

.hero-block__overlay--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
